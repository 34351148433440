import React from 'react';
import { Box } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import styled from 'styled-components';

const Container = styled(Box)`
  margin-bottom: var(--section-bm);
  padding-bottom: 3rem;
`;

const Heading = styled.h1`
  text-align: center;
  color: white;
  font-size: 2.5rem;
  line-height: var(--header-line-height);
  font-weight: 550;
  letter-spacing: -1.2px;
  margin-bottom: 2rem;
  margin-top: 8rem;

  @media only screen and (max-width: 1020px) {
    margin-top: 5rem;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  margin: auto auto 2rem auto;

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    width: 600px;
  }

  @media only screen and (min-width: 525px) and (max-width: 767px) {
    width: 400px;
  }

  @media only screen and (min-width: 320px) and (max-width: 524px) {
    width: 300px;
  }
`;

const RoleDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid gray;

  &:hover {
    cursor: pointer;
    filter: brightness(80%);
  }
`;

const RoleType = styled.p`
  color: white;
  font-size: 1.25rem;
  font-weight: 550;
`;

const RoleName = styled.p`
  color: white;
  font-size: 1rem;
  font-weight: 500;
`;

const positions = [
  {
    roleType: 'Engineering',
    roles: [
      {
        name: 'Senior Software Engineer, Rust',
        link: 'https://wellfound.com/jobs/2872616-senior-software-engineer-rust',
      },
      {
        name: 'Senior Software Engineer, Solidity',
        link: 'https://wellfound.com/jobs/2872620-senior-software-engineer-solidity',
      },
      {
        name: 'Senior Softare Engineer, Frontend',
        link: 'https://wellfound.com/jobs/2872623-senior-software-engineer-frontend',
      },
      {
        name: 'Machine Learning Engineer',
        link: 'https://wellfound.com/jobs/2873037-machine-learning-engineer',
      },
      {
        name: 'Security Engineer',
        link: 'https://wellfound.com/jobs/2873055-security-engineer',
      },
    ]
  },
  {
    roleType: 'Research',
    roles: [
      {
        name: 'Applied Cryptographer',
        link: 'https://wellfound.com/jobs/2873061-applied-cryptographer',
      },
    ]
  },
  {
    roleType: 'Growth',
    roles: [
      {
        name: 'Business Development Lead',
        link: 'https://wellfound.com/jobs/2873557-business-development-lead',
      },
      {
        name: 'Community Marketing Lead',
        link: 'https://wellfound.com/jobs/2873079-community-marketing-lead',
      },
    ]
  },
  {
    roleType: 'General',
    roles: [
      {
        name: 'Extraordinary Talent',
        link: 'https://wellfound.com/jobs/2873470-extraordinary-ability',
      },
    ]
  },
]

const Careers = () => {
  return (
    <Container zIndex="999" id="careers">
      <Heading>Careers</Heading>
      {positions.map((elem, index) => (
        <SubContainer key={index}>
          <RoleType>{elem.roleType}</RoleType>
          {elem.roles.map((role, index) => (
            <RoleDiv
              key={index}
              onClick={() => {
                window.open(role.link);
              }}
            >
              <RoleName>{role.name}</RoleName>
              <ArrowForwardIcon boxSize={4} color={'white'} marginLeft={'auto'} />
            </RoleDiv>
          ))}
        </SubContainer>
      ))}
    </Container>
  );
}

export default Careers;
