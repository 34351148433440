import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import circularLogo from '../../assets/logo.svg';
import logo from '../../assets/andalusia-labs.svg';
import styled from 'styled-components';
import Menu from './Menu';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const Logo = styled.img`
  width: 10rem;
`;

const SmallLogo = styled.img`
  width: 2rem;
`;

const Header = () => {
  const [view, setView] = useState("mobile");

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 600) {
        setView("mobile");
      } else {
        setView("desktop");
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Container>
      <RouteLink to="/" style={{zIndex: 999}}>
        <Stack spacing="0.5rem" direction="row" align="center" _hover={{ cursor: 'pointer' }}>
          {view === "desktop" ? (
            <Logo src={logo} alt="logo" />
          ) : (
            <SmallLogo src={circularLogo} alt="logo" />
          )}
        </Stack>
      </RouteLink>
      <Menu />
    </Container>
  );
}

export default Header;
