import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import pattern from '../../assets/pattern-grid.png';

const Container = styled(Box)`
  margin-bottom: 5rem;
  padding-top: 1rem;
`;

const Heading = styled.h1`
  text-align: left;
  margin-left: 2rem;
  color: #EDEDED;
  font-size: 2.5rem;
  line-height: var(--header-line-height);
  font-weight: 300;
  letter-spacing: -1.2px;
  margin-top: 18%;

  @media only screen and (max-width: 876px) {
    margin-top: 10%;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 0rem;
    text-align: center;
    margin-left: 0rem;
  }
`;

const Copy = styled.p`
  font-size: 1.15rem;
  color: #9A9A9A;
  margin-top: 2rem;
  width: 90%;
  margin-left: 2rem;
  font-weight: 300;
  letter-spacing: -0.05rem;
  line-height: 1.5rem;

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-left: 0rem;
  }
`;

const Blur = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  width: 100vw;

  @media only screen and (max-width: 876px) {
    height: 35rem;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const Team = () => {
  return (
    <Container zIndex="999" id="team">
      <Blur src={pattern} alt="grid" />
      <Heading>Team</Heading>
      <Copy>
        Andalusia Labs is powered by a nimble and impactful team with deep expertise in building massively
        scalable software systems, AI, fintech, and blockchain products at Coinbase, Google, AWS, Microsoft,
        Twitter, Social Capital, Goldman Sachs, and High-Frequency Trading Firms.
      </Copy>
      <Copy>
        Backed by the best investors and institutions in the world, Andalusia Labs builds digital
        infrastructure that powers billions of dollars of digital assets around the globe.
      </Copy>
      <Copy>
        The team is expanding and actively looking for extraordinary individuals. If you believe you may be a good fit,
        please take a look at our roles below or contact us directly at info@andalusia-labs.com.
      </Copy>
    </Container>
  );
}

export default Team;
