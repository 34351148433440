import React from 'react';
import Info from './Info';
import styled from 'styled-components';

const FlexBox = styled.div`
  overflow: hidden;
  margin-bottom: 4rem;

  @media only screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 499px) {
    margin-bottom: 0rem;
  }
`;

const Intro = () => {
  return (
    <FlexBox>
      <Info />
    </FlexBox>
  );
}

export default Intro;
