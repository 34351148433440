import React from "react";
import { Box } from "@chakra-ui/react";
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  max-height: 5rem;
`;

const Logo = styled.img`
  width: 70%;
  margin: auto;
`;

const SLogo = styled.img`
  width: 50%;
  margin: auto;
  margin-top: 2rem;
`;

const PLogo = styled.img`
  width: 60%;
  margin: auto;
`;

const Firm = ({ logo, altText }) => {
  return (
    <Container>
      {altText === 'Proof Group' ? (
        <PLogo src={logo} alt={altText} />
      ) : (
        (altText === 'Mubadala Capital' || altText === 'Lightspeed Venture Partners') ? (
          <SLogo src={logo} alt={altText} />
        ) : (
          <Logo src={logo} alt={altText} />
        )
      )}
    </Container>
  );
}

export default Firm;
