import React from 'react';
import { Box } from '@chakra-ui/react';
import GridLink from './GridLink';
import styled from 'styled-components';
import logo from '../../assets/andalusia-labs.svg';

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--section-bm);
`;

const Logo = styled.img`
  width: 11rem;
  margin-bottom: 3rem;
`;

const Footer = () => {
  return (
    <FlexBox>
      <Logo src={logo} alt="logo" />
      <GridLink />
      <p style={{color: '#d7d7d7', fontSize: '0.75rem'}}>Andalusia Labs &copy; {new Date().getFullYear()}. All rights reserved.</p>
    </FlexBox>
  );
}

export default Footer;
